<template>
  <div class="pt-10">
    <div
      class="md:w-1024 mx-auto py-12 px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
    >
      <div class="w-full text-left text-textBlack">
        <h1 class="mt-4 mb-5 text-4xl font-semibold text-primary">
          neo’s Learning Solutions
        </h1>
        <h5 class="mb-4 font-semibold">
          Advanced AI Technology <br />
          For Adult English Language Learners
        </h5>
        <p class="w-full text-base text-justify">
          Built by educators for educators, neo’s English Learning solutions
          allows learners ages 4 to adult achieve their English fluency goals
          regardless of whether your institution requires fully online
          instruction or a blend of classroom and online. <br /><br />
          Our comprehensive solution allows students to learn independently,
          while it works behind the scenes to serve and support teachers and
          administrators. Support includes instructional assistance, student
          progress monitoring, grading, Recommended Class Activities, workbooks,
          and much more. You administer your program effortlessly from the
          neoDashboard, an online platform that keeps your program running
          effectively.
        </p>
      </div>
    </div>
    <div class="bg-softGrey">
      <div
        class="md:w-1024 mx-auto pt-12 px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
      >
        <div class="w-full text-left text-textBlack">
          <h1 class="mb-6 text-4xl text-primary font-semibold">
            Getting Started – neo's Assessment Test
          </h1>
          <p class="w-full text-justify text-base">
            neoAssess evaluates your students and places them in the English
            level that is right for them. This paves the way for efficient
            learning from the very beginning. Learners then begin to practice
            with neoStudy, which continually adjusts to each student’s learning
            needs, thanks to neo’s integrated artificial intelligence (AI).
            <router-link to="#">Learn more</router-link>
          </p>
        </div>
      </div>

      <div
        class="md:w-1024 mx-auto mt-8 pb-12 px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
      >
        <div class="w-full text-left text-textBlack">
          <h1 class="mb-6 text-4xl text-primary font-semibold">
            Easily Accessible Courses
          </h1>
          <img
            class="h-80"
            ref="prefetch"
            src="@/assets/images/icons/girl_laptop_2.png"
            alt="girl_laptop_2"
          />
        </div>
      </div>
    </div>
    <div
      class="md:w-1024 mx-auto pt-12 px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
    >
      <div class="w-full text-left text-textBlack">
        <h1 class="text-4xl text-primary font-semibold">
          Powerful learning tool – neoStudy​
        </h1>
        <p class="w-full md:w-10/12 pt-6 text-base">
          neoStudy delivers English language learning to learners of all ages,
          making neo courses easily available online or on a mobile app:​
        </p>
      </div>
    </div>
    <div
      class="md:w-1024 mx-auto pt-4 pb-12 px-4 md:px-0 flex flex-col-reverse md:flex-row items-center justify-center"
    >
      <div class="w-full text-left">
        <div class="flex flex-col md:flex-row items-start justify-between">
          <img
            class="h-80"
            ref="prefetch"
            src="@/assets/images/icons/happy-good-looking-woman-showing-digital-tablet-display.png"
            alt="happy-good-looking-woman-showing-digital-tablet-display"
          />
          <div class="pt-4 md:pt-0 md:pl-10">
            <h5 class="font-semibold text-xl text-primary">neoStudy Online</h5>
            <p class="pt-2 text-base text-justify text-textBlack">
              Students can easily access neo courseware using Google Chrome on
              Chromebooks or Windows or Mac computers. ​
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="md:w-1024 mx-auto py-12 px-4 md:px-0 flex flex-col-reverse md:flex-row items-center justify-center"
    >
      <div class="w-full text-left text-textBlack">
        <div class="flex flex-col md:flex-row items-start justify-between">
          <div class="pt-4 md:pt-0 md:pr-10">
            <h5 class="font-semibold text-xl text-primary">neoStudy App</h5>
            <p class="pt-2 text-base text-justify">
              Students can also extend their learning using their same
              credentials to log in on their tablets or smartphones. Regardless
              of when and where they log in, their study data synchronizes
              across all their devices to our secure cloud servers. ​
            </p>
          </div>
          <img
            class="h-80"
            ref="prefetch"
            src="@/assets/images/icons/girl_laptop_2.png"
            alt="girl_laptop_2"
          />
        </div>
      </div>
    </div>
    <div class="bg-div bg-cover" :style="`background-image: url(${imageUrl})`">
      <div
        class="md:w-1024 mx-auto py-12 px-4 md:px-0 flex flex-col-reverse md:flex-row items-center justify-center"
      >
        <div class="w-full text-left text-softWhite">
          <div class="flex flex-col md:flex-row items-center justify-between">
            <div class="pt-4 md:pt-0 md:pr-10">
              <h5
                class="mb-6 text-base font-semibold tracking-widest cursor-pointer"
              >
                neoLive
              </h5>
              <h1 class="text-4xl text-lightBlue font-semibold">
                Online Coaching with neoLive
              </h1>
              <p class="mt-8 mx-auto text-justify">
                Our trained coaches will meet online with learners at least
                every two weeks to develop their English-speaking skills. Thanks
                to neo’s advanced AI technology, coaches can specifically
                address any problem areas learners face during the self-study
                sessions. neoLive provides current study data and individualized
                extension activities to the coach before every coaching session
                to allow students to personalize the language they've practiced
                in the previous two weeks.
              </p>
            </div>
            <img
              ref="prefetch"
              src="@/assets/images/icons/onlinecoaching_icon.png"
              alt="onlinecoaching_icon"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="md:w-1024 mx-auto py-12 px-4 md:px-0 flex flex-col-reverse md:flex-col items-center justify-center"
    >
      <div class="w-full text-left text-textBlack">
        <h1 class="text-4xl text-primary font-semibold">
          Goal based Learning – neo Certification.
        </h1>
        <p class="pt-8 text-base text-justify">
          Thanks to nexgen’s AI, students learn at their own pace and earn study
          points as they advance towards the learning goals you set for them. As
          they earn points, new lessons and Mastery Tests will open, along with
          Certification eTests as they complete the Common European Framework of
          Reference for Languages (CEFR) level’s courses.
          <br /><br />
          In order to move from one level to the next, learners must
          successfully complete all the lessons and tests for that level. When
          all requirements are met, learners earn internationally accepted
          English certificates that are fully aligned to the CEFR.
        </p>
      </div>
      <div class="w-full my-8 text-left text-textBlack">
        <div class="flex flex-col md:flex-row items-start justify-start">
          <img
            class="h-80"
            ref="prefetch"
            src="@/assets/images/icons/happy-good-looking-woman-showing-digital-tablet-display.png"
            alt="happy-good-looking-woman-showing-digital-tablet-display"
          />
          <div>
            <p class="md:pl-10 pt-2 text-base text-justify">
              Students can also extend their learning using their same
              credentials to log in on their tablets or smartphones. Regardless
              of when and where they log in, their study data synchronizes
              across all their devices to our secure cloud servers. ​
            </p>
          </div>
        </div>
      </div>
      <div class="w-full">
        <p class="text-base text-textBlack text-justify">
          neo Learning Solutions has options for students of all ages and life
          situations. To learn more about neo for your specific needs, please
          click on the links below.
        </p>
        <ul class="mt-6 flex flex-wrap justify-between">
          <li
            class="w-6/12 font-bold text-lightBlue cursor-pointer"
            v-for="(data, i) in solutionOptions"
            :key="i"
          >
            {{ data }}
          </li>
        </ul>
      </div>
    </div>
    <h4
      class="md:w-1024 mx-auto px-4 md:px-0 pt-4 pb-12 text-right text-base text-secondary font-semibold uppercase tracking-widest cursor-pointer"
      @click="$router.push('/neoassess')"
    >
      next &#62;
    </h4>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl: require("@/assets/images/bgspace.png"),
      purpleColor: "#D6E0FF",
      greenColor: "#E5FFC6",
      orangeColor: "#FFF1D6",
      turquoiseColor: "#D6FFF9",
      whiteColor: "#FCFCFC",
      solutionOptions: [
        "Elementary School",
        "College/Vocational",
        "Middle School",
        "Classroom Management",
        "Highschool",
        "Independent Study",
        "Higher Learning",
        "Professional Development",
      ],
    };
  },
};
</script>

<style scoped>
</style>